import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from 'grommet';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import EquipamentosCadastroForm from '../../../Forms/Equipamentos/EquipamentosCadastroForm';
import inserirEquipamentoInitialValues from '../../Helpers/inserirEquipamentoInitialValues';
import inserirEquipamentoSubmit from '../../Helpers/inserirEquipamentoSubmit';

export interface AdicionarEquipamentoProps {}

const AdicionarEquipamento: React.FC<AdicionarEquipamentoProps> = ({}): JSX.Element => {
  const navigate = useNavigate();

  const onCancel = (): void => {
    navigate('/owner/manageEquipamentos');
  };

  return (
    <PageWrapper>
      <PageTitle
        Text="Adicionar Equipamento"
      />
      <Box
        width="large"
      >
        <EquipamentosCadastroForm
          initialValues={inserirEquipamentoInitialValues()}
          onSubmit={inserirEquipamentoSubmit}
          onCancel={onCancel}
        />
      </Box>
    </PageWrapper>
  );
};

AdicionarEquipamento.displayName = 'AdicionarEquipamento';

export default AdicionarEquipamento;
