import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button } from 'grommet';
import { Checkmark, Edit, Trash } from 'grommet-icons';
import { toast } from 'react-toastify';
import ConfirmModal from '../../ConfirmModal/ConfirmModal';
import { IUsuarioDocumento } from '../../../../Interfaces/IUsuarioDocumento';
import onChangeActivePropertyForDocumentosUsuarios from '../Helpers/onChangeActivePropertyForDocumentosUsuarios';
import deleteUsuariosDocumentos from '../Helpers/deleteUsuariosDocumentos';

export interface ManageUsuariosDocumentosActionsProps {
  documento: IUsuarioDocumento;
  refreshData: () => void;
}

const ManageUsuariosDocumentosActions: React.FC<ManageUsuariosDocumentosActionsProps> = ({ documento, refreshData }): JSX.Element => {
  const [action, setAction] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const changeShowModal = (): void => {
    setShowModal(!showModal);
  };


  const navigate = useNavigate();

  const changeActiveProp = async (): Promise<void> => {
    await onChangeActivePropertyForDocumentosUsuarios(documento.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Propriedade ATIVO do documento alterada com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao alterar a propriedade ATIVO do documento');
        }
      })
      .catch((): void => {
        toast.error('Erro ao alterar a propriedade ATIVO do documento');
      });
  };

  const onEditDocumentoClick = (): void => {
    navigate(`/owner/editarUsuariosDocumentos/${documento.Guid}`);
  };

  const onChangeActiveClick = (): void => {
    setAction('changeActive');
    changeShowModal();
  };

  const confirmModalTitle = (): string => {
    switch (action) {
      case 'changeActive':
        return 'Alterar propriedade ATIVO?';
      case 'delete':
        return 'Excluir documento?';
      default:
        return '';
    }
  };

  const confirmModalText = (): string => {
    switch (action) {
      case 'changeActive':
        return `O documento ${documento.Nome} está ${documento.Ativo ? 'ATIVO' : 'INATIVO'}. Deseja alterar?`;
      case 'delete':
        return `Deseja excluir permanentemente o documento ${documento.Nome}?`;
      default:
        return '';
    }
  };

  const onDeleteUsuariosDocumentos = async (): Promise<void> => {
    await deleteUsuariosDocumentos(documento.Guid!)
      .then((data): void => {
        if (data.Success) {
          toast.success('Documento excluído com sucesso');
          refreshData();
        } else {
          toast.error('Erro ao excluir o documento');
        }
      })
      .catch((): void => {
        toast.error('Erro ao excluir o documento');
      });
  };

  const onDeleteClick = (): void => {
    setAction('delete');
    changeShowModal();
  };

  const onConfirm = (): void => {
    switch (action) {
      case 'changeActive':
        changeActiveProp();
        break;
      case 'delete':
        onDeleteUsuariosDocumentos();
        break;
      default:
        break;
    }

    changeShowModal();
  };

  return (
    <>
      <Box
        direction="row"
        gap="none"
        pad="none"
      >
        {/* <ManageEmpresasDocumentoDownload
          documento={documento}
        /> */}
        <Button
          icon={<Edit />}
          onClick={onEditDocumentoClick}
        />
        <Button
          icon={<Checkmark />}
          title="Alterar a propriedade 'ATIVO' do documento"
          onClick={onChangeActiveClick}
        />
        <Button
          icon={<Trash />}
          onClick= {onDeleteClick}
          title="Excluir documento"
        />
      </Box>
      {
        (showModal) && (
          <ConfirmModal
            title={confirmModalTitle()}
            text={confirmModalText()}
            onCancel={changeShowModal}
            onConfirm={onConfirm}
          />
        )
      }
    </>
  );
};

ManageUsuariosDocumentosActions.displayName = 'ManageUsuariosDocumentosActions';

export default ManageUsuariosDocumentosActions;
