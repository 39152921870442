import React, { useEffect, useState } from 'react';
import PageWrapper from '../../../PageWrapper/PageWrapper';
import PageTitle from '../../../PageTitle/PageTitle';
import { Box } from 'grommet';
import { useParams, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from '../../../Loading/Loading';
import EquipamentosCadastroForm from '../../../Forms/Equipamentos/EquipamentosCadastroForm';
import EditarEquipamentoSubmit from '../../Helpers/editarEquipamentoSubmit';
import { IEquipamento } from '../../../../../Interfaces/IEquipamento';
import getEquipamentoInfoByGuid from '../../Helpers/editarEquipamentoInitialValues';

export interface EditarEquipamentoProps {}

const EditarEquipamento: React.FC<EditarEquipamentoProps> = ({}): JSX.Element => {
  const { guid } = useParams<{ guid: string }>();
  const navigate = useNavigate();

  const [loading, setLoading] = useState<boolean>(true);
  const [equipamento, setEquipamento] = useState<IEquipamento>({});

  const onCancel = (): void => navigate('/owner/manageEquipamentos');

  const getInitialData = async (): Promise<void> => {
    await getEquipamentoInfoByGuid(guid!)
      .then((data): void => {
        if (data.Success) {
          const initialValues: IEquipamento = data.Object!;
          setEquipamento(initialValues);
        } else {
          toast.error('Erro ao buscar informações do equipamento');
        }
        setLoading(false);
      })
      .catch((): void => {
        toast.error('Erro ao buscar informações do equipamento');
      });
  };

  useEffect((): void => {
    getInitialData();
  }, []);

  return (
    <PageWrapper>
      <PageTitle
        Text="Editar equipamento"
      />
      <Box
        width="large"
      >
        {
          (loading) ? (<Loading />)
            : (
              <EquipamentosCadastroForm
                initialValues={equipamento}
                onSubmit={EditarEquipamentoSubmit}
                onCancel={onCancel}
              />
            )
        }
      </Box>
    </PageWrapper>
  );
};

EditarEquipamento.displayName = 'EditarEquipamento';

export default EditarEquipamento;
