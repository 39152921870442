import {
  Box, Button, FileInput, FormExtendedEvent, FormField, DateInput, TextInput, ResponsiveContext, TextArea,
} from 'grommet';
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import FormWrapper from '../../FormWrapper/FormWrapper';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';
import { IEmpresaDocumento } from '../../../../Interfaces/IEmpresaDocumento';
import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';
import DropDownTiposDeDocumentos from '../../DropDownTiposDeDocumentos/DropDownTiposDeDocumentos';

export interface DocumentosFormProps {
  initialValues: IEmpresaDocumento;
  onSubmit: (values: IEmpresaDocumento) => Promise<IBasicReturn>;
  onCancel?: () => void;
  editting?: boolean;
}

const DocumentosForm: React.FC<DocumentosFormProps> = ({ initialValues, onSubmit, onCancel, editting = false }): JSX.Element => {
  const size = useContext(ResponsiveContext);

  const fileRef = useRef<HTMLInputElement>(null);

  const maxFileSize: number = 4 * 1024 * 1024;

  const [submitting, setSubmitting] = useState<boolean>(false);
  const [values, setValues] = useState<IEmpresaDocumento>(initialValues);
  const onChange = (nextValues: IEmpresaDocumento): void => setValues(nextValues);

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IEmpresaDocumento>): Promise<void> => {
    setSubmitting(true);

    await onSubmit(evt.value)
      .then((data): void => {
        if (data.Success) {
          toast.success('Documento salvo com sucesso');

          if (onCancel) onCancel();
        } else {
          toast.error('Erro ao salvar documento');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        toast.error('Erro ao salvar documento');
        setSubmitting(false);
      });
  };

  const validateLength = (value: string): string | undefined => {
    if (value.length < 3 || value.length > 100) {
      return 'O campo deve ter entre 3 e 100 caracteres';
    }
  };

  const activeChange = (value: boolean): void => {
    setValues({ ...values, Ativo: value });
  };

  const validadeChange = (value: boolean): void => {
    setValues({ ...values, Vencimento: value });
  };

  useEffect((): void => {
    setValues({ ...values, Tipo: { Guid: values.TipoDocumento } });
  }, [values.TipoDocumento]);

  const validateVencimento = (value: string): string | undefined => {
    if (values.Vencimento && !value) {
      return 'Selecione a data de validade do documento';
    }
  };

  const setUpload = async (file: File): Promise<void> => {
    if (file.size > maxFileSize) {
      toast.error('Arquivo muito grande (máximo 4MB)');
      return;
    }

    const reader = new FileReader();

    reader.onload = (): void => {
      const { result } = reader;

      const [extension] = file.name.split('.').reverse();

      if (!extension) {
        toast.error('Arquivo sem extensão');
        return;
      }

      setValues({
        ...values,
        UploadNome: file.name,
        Upload: {
          UploadMimeType: file.type,
          UploadBase64: result!.toString(),
          UploadExtension: extension,
        },
      });

      reader.onerror = (): void => {
        toast.error('Erro ao carregar imagem');
      };
    };

    reader.readAsDataURL(file);
  };

  useEffect((): void => {
    if (values.UploadNome === '') return;

    if (fileRef.current && fileRef.current.files) {
      setUpload(fileRef.current.files[0]);
    }
  }, [fileRef.current?.value]);

  const onChangeFile = (_evt: ChangeEvent<HTMLInputElement> | undefined, { files }: { files?: File[]; } = {}): void => {
    if (!files || !files[0]) return;

    setUpload(files[0]);
  };

  return (
    <FormWrapper values={values} onChange={onChange} onSubmit={handleSubmit}>
      <FormField
        label="Nome do Documento"
        name="Nome"
        width="100%"
        validate={validateLength}
        required
      >
        <TextInput
          name="Nome"
          aria-label="Por favor informe o nome do documento"
          title="Por favor informe o nome do documento"
        />
      </FormField>

      <FormField
        label="Descrição"
        name="Descricao"
        width="100%"
      >
        <TextArea
          name="Descricao"
          aria-label="Por favor informe a descrição do documento"
          title="Por favor informe a descrição do documento"
          rows={5}
        />
      </FormField>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small">
        <InterdevSwitch
          activeLabel="Ativo"
          inactiveLabel="Inativo"
          value={values.Ativo || false}
          onChange={activeChange}
          name="Ativo"
          title="Alterar o Status do documento"
          text="Status do documento"
        />
        <DropDownTiposDeDocumentos
          name="TipoDocumento"
          label="Tipo de Documento"
          title="Por favor selecione o tipo de documento"
          disabled={submitting}
        />
      </Box>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small">
        <InterdevSwitch
          activeLabel="Sim"
          inactiveLabel="Não"
          value={values.Vencimento || false}
          onChange={validadeChange}
          name="Vencimento"
          title="Documento tem data de validade?"
          text="Documento tem data de validade?"
        />

        {
          (values.Vencimento) && (
            <FormField
              label="Data de Validade (AAAA/MM/DD)"
              name="DataVencimento"
              width="100%"
              validate={validateVencimento}
            >
              <DateInput
                name="DataVencimento"
                format="yyyy/mm/dd"
                aria-label="Por favor selecione a data de validade do documento"
                title="Por favor selecione a data de validade do documento"
              />
            </FormField>
          )
        }
      </Box>

      {
        (!editting) && (
          <FormField
            label="Upload do Documento"
            name="UploadNome"
            width="100%"
            required
          >
            <FileInput
              ref={fileRef}
              multiple={false}
              name="UploadNome"
              maxSize={maxFileSize}
              accept="application/pdf,image/png,image/jpeg"
              onChange={onChangeFile}
              messages={{
                browse: 'Carregar',
                dropPromptMultiple: 'Arraste seu documento aqui ou',
                dropPrompt: 'Arraste aqui um arquivo .PDF, .PNG ou .JPG ou',
                files: 'Documentos',
                remove: 'Remover',
                removeAll: 'Remover todos',
                maxFile: 'O arquivo é muito grande',
              }}
            />
          </FormField>
        )
      }

      <Box
        direction="row"
        gap="medium"
        justify="start"
        margin={{
          top: 'medium',
          bottom: 'medium',
        }}
      >
        <Button
          type="submit"
          label="Salvar"
          disabled={submitting}
          primary
        />
        <Button
          label="Cancelar"
          onClick={onClear}
        />
      </Box>
    </FormWrapper>
  );
};

DocumentosForm.displayName = 'DocumentosForm';

export default DocumentosForm;
