import {
  Box, Button, FormExtendedEvent, FormField, ResponsiveContext, TextArea, TextInput,
} from 'grommet';
import React, { useContext } from 'react';
import { toast } from 'react-toastify';
import { IEquipamento } from '../../../../Interfaces/IEquipamento';
import FormWrapper from '../../FormWrapper/FormWrapper';
import InterdevSwitch from '../../InterdevSwitch/InterdevSwitch';
import { IBasicReturn } from '../../../../Interfaces/IBasicReturn';

export interface EquipamentosCadastroFormProps {
  initialValues: IEquipamento;
  onSubmit: (values: IEquipamento) => Promise<IBasicReturn>;
  onCancel?: () => void;
}

const EquipamentosCadastroForm: React.FC<EquipamentosCadastroFormProps> = ({ initialValues, onSubmit, onCancel,
}): JSX.Element => {
  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [values, setValues] = React.useState<IEquipamento>(initialValues);
  const onChange = (nextValues: IEquipamento): void => setValues(nextValues);

  const onClear = (): void => {
    if (onCancel) {
      onCancel();
    } else {
      setValues(initialValues);
    }
  };

  const handleSubmit = async (evt: FormExtendedEvent<IEquipamento>): Promise<void> => {
    const { value } = evt;

    setSubmitting(true);

    await onSubmit(value)
      .then((data): void => {
        if (data.Success) {
          onClear();
          toast.success('Equipamento salvo com sucesso');
        } else {
          toast.error('Erro ao salvar o equipamento');
        }
        setSubmitting(false);
      })
      .catch((): void => {
        toast.error('Erro ao salvar o equipamento');
        setSubmitting(false);
      });
  };

  const size = useContext(ResponsiveContext);

  const validateLength = (value: string): string | undefined => {
    if (value && (value.length < 3 || value.length > 100)) {
      return 'O campo deve ter entre 3 e 100 caracteres';
    }
  };


  const activeChange = (value: boolean): void => {
    setValues({ ...values, Ativo: value });
  };

  return (
    <FormWrapper values={values} onChange={onChange} onSubmit={handleSubmit}>
      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="center">
        <FormField label="Equipamento" name="Nome" width="100%" required validate={validateLength}>
          <TextInput name="Nome" aria-label="Por favor informe o nome do Equipamento" title="Por favor informe o nome do Equipamento" />
        </FormField>

        <FormField label="Número de Série" name="NumeroDeSerie" width="100%" required validate={validateLength}>
          <TextInput name="NumeroDeSerie" aria-label="Por favor informe o número de série" title="Por favor informe o número de série" />
        </FormField>
      </Box>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="center">
        <FormField label="Ano" name="Ano" width="100%" validate={validateLength}>
          <TextInput name="Ano" aria-label="Por favor informe o ano de fabricação do aparelho" title="Por favor informe o ano de fabricação do aparelho" />
        </FormField>

        <FormField label="Modelo" name="Modelo" width="100%" validate={validateLength}>
          <TextInput name="Modelo" aria-label="Por favor informe o modelo" title="Por favor informe o modelo" />
        </FormField>
      </Box>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="center">
        <FormField label="Número do Certificado" name="Certificado" width="100%" validate={validateLength}>
          <TextInput name="Certificado" aria-label="Por favor informe o número do certificado" title="Por favor informe o número do certificado" />
        </FormField>

        <InterdevSwitch
          activeLabel="Ativo"
          inactiveLabel="Inativo"
          value={values.Ativo || false}
          onChange={activeChange}
          name="Ativo"
          title="Alterar o Status do equipamento"
          text="Status do equipamento"
        />
      </Box>

      <Box direction={size === 'small' ? 'column' : 'row'} gap="small" justify="center">
        <FormField label="Observação" name="Observação" width="100%" validate={validateLength}>
          <TextArea
            name="Observação"
            aria-label="Por favor insira observações"
            title="Por favor insira observações"
            rows={5}
          />
        </FormField>
      </Box>

      <Box direction="row" gap="medium" justify="start" margin={{ top: 'medium', bottom: 'medium' }}>
        <Button type="submit" label="Salvar" title="Salvar" disabled={submitting} primary reverse />
        <Button label="Cancelar" title="Cancelar" onClick={onClear} reverse />
      </Box>
    </FormWrapper>
  );
};

EquipamentosCadastroForm.displayName = 'EquipamentosCadastroForm';

export default EquipamentosCadastroForm;
